@import 'styles/_colors.scss';

.panel-item {
  appearance: none;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-items: stretch;
  flex-flow: row wrap;
  padding: 0 32px;

  width: 100%;
  min-height: 50px;

  border: none;
  border-radius: 6px;

  &.adjust-top {
    margin-top: -24px;
  }

  &.fluid {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
    border-radius: 0;

    &:hover,
    &:active {
      border-radius: 0;
    }
  }

  &.uppercase {
    text-transform: uppercase;

    .panel-item-header-title {
      letter-spacing: 0.06em;
      font-size: 12px !important;
    }
  }

  &.hidden {
    > .panel-item-header,
    > .accordion-title {
      opacity: 0.5;
    }
  }

  &.item {
    .panel-item-header,
    .accordion-title {
      background-color: $lightGrey;
      border-radius: 6px;

      &:hover,
      &:active {
        background-color: $grey;
      }
    }
  }

  &.header {
    .panel-item-header {
      &:hover {
        .panel-item-header-wrapper {
          &:after {
            opacity: 1;
          }
        }
      }

      .panel-item-header-wrapper {
        position: relative;
        padding-right: 40px;

        &:after {
          content: '';
          opacity: 0;

          transition: opacity 0.2s;

          mask-image: url('../../Icon/icons/edit.svg');
          mask-type: alpha;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: 16px;
          background-color: $black;

          height: 24px;
          width: 24px;

          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }

        .panel-item-header-title {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  .panel-item-header {
    .panel-item-header-wrapper {
      text-align: left;
      overflow: hidden;

      .panel-item-header-title,
      .panel-item-header-subtitle {
        display: block;
        text-align: left;
        font-size: 14px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .panel-item-header-subtitle {
        color: $subtitle;
        font-size: 12px;
        font-weight: normal;
      }
    }

    .panel-item-header-actions + .panel-item-header-actions {
      margin-left: 0;
    }

    .panel-item-header-actions {
      position: relative;
      margin-left: auto;

      > * {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }

      .right-panel-overflow-menu-trigger {
        position: relative;
        right: unset;
        top: unset;

        .icon {
          background-color: $black;
        }
      }
    }
  }

  &.accordion {
    &.accordion-expanded,
    &.header {
      box-shadow: 0 0 0 1px $border;
    }

    &.accordion-boxed {
      &.accordion-expanded {
        .accordion-title {
          background-color: transparent;
          color: $body;

          &:hover,
          &:active {
            background-color: $lightGrey;
          }
        }

        .accordion-children {
          background-color: transparent;

          > .accordion-boxed-done {
            margin-left: -16px;
            margin-right: -16px;
          }
        }
      }

      > .accordion-title {
        padding: inherit;
      }
    }

    .accordion-children {
      width: 100%;
    }

    > * {
      &:last-child {
        padding: 0;
        > .accordion-children {
          padding: 16px 32px;
        }
      }
    }

    .accordion-children {
      > * {
        margin-bottom: 8px;

        &:last-child:not(.accordion-boxed-done) {
          margin-bottom: 0;
        }
      }
    }
  }

  &.accordion > *,
  &.accordion-expanded > *,
  .panel-item-header {
    flex-basis: calc(100% + 64px);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -32px;
    margin-right: -32px;
  }

  .accordion-title,
  .panel-item-header {
    > .icon {
      background-color: $black;
    }
  }

  .panel-item-header,
  &.accordion > .accordion-title {
    width: 100%;
    min-height: 50px;
    font-size: 14px;
    padding: 12px 32px;

    background-color: transparent;
    transition: background 0.2s;

    > .icon {
      margin-right: 8px;
    }

    &:active,
    &:hover {
      cursor: pointer;
      background-color: $lightGrey;
    }
  }

  .panel-item-list {
    list-style-type: none;
    padding-left: 16px;

    > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // COLORS
  &.teal,
  &.screens {
    color: $screens;

    .panel-item-header > .icon {
      background-color: $screens;
    }

    .panel-item-header,
    .accordion-title {
      &:active,
      &:hover {
        background-color: rgba($screens, 0.1);
      }
    }
  }
}
